import adapter from "@o-din/src/adapter";

export default {
  lk: {
    posts: {
      index: function (params = {}) {
        return adapter.get(`api/v4/lk/posts.json`, { params: params })
      },
      create: function (params) {
        return adapter.post('api/v4/lk/posts.json', params)
      },
      update: function (id, params) {
        return adapter.patch(`api/v4/lk/posts/${id}.json`, params)
      },
      get: function(id) {
        return adapter.get(`api/v4/lk/posts/${id}.json`)
      },
      destroy: function(id) {
        return adapter.delete(`api/v4/lk/posts/${id}.json`)
      }
    },
    documents: {
      index: function (params = {}) {
        return adapter.get(`api/v4/lk/documents.json`, { params: params })
      },
      create: function (params) {
        return adapter.post('api/v4/lk/documents.json', params)
      },
      update: function (id, params) {
        return adapter.patch(`api/v4/lk/documents/${id}.json`, params)
      },
      get: function(id) {
        return adapter.get(`api/v4/lk/documents/${id}.json`)
      },
      destroy: function(id) {
        return adapter.delete(`api/v4/lk/documents/${id}.json`)
      }
    },
    surveys: {
      index: function(params = {}) {
        return adapter.get(`api/v4/lk/surveys.json`, { params: params })
      },
      create: function (params) {
        return adapter.post('api/v4/lk/surveys.json', params)
      },
      update: function (id, params) {
        return adapter.patch(`api/v4/lk/surveys/${id}.json`, params)
      },
      get: function(id) {
        return adapter.get(`api/v4/lk/surveys/${id}.json`)
      },
      destroy: function(id) {
        return adapter.delete(`api/v4/surveys/${id}.json`)
      }
    },
    survey_results: {
      index: function(params = {}) {
        return adapter.get(`api/v4/lk/survey_results.json`, { params: params })
      },
      create: function (params) {
        return adapter.post('api/v4/lk/survey_results.json', params)
      },
      update: function (id, params) {
        return adapter.patch(`api/v4/lk/survey_results/${id}.json`, params)
      },
      get: function(id) {
        return adapter.get(`api/v4/lk/survey_results/${id}.json`)
      }
    }
  }
}
