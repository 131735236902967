import adapter from "@o-din/src/adapter";

export default {
  ku: {
    equipment_data: {
      index: function(params = {}) {
        return adapter.get("api/v4/ku/equipment_data.json", { params: params })
      },
      show: function(id) {
        return adapter.get(`api/v4/ku/equipment_data/${id}.json`)
      },
      create: function(params) {
        return adapter.post("api/v4/ku/equipment_data.json", params)
      },
      destroy: function(id) {
        return adapter.delete(`api/v4/ku/equipment_data/${id}.json`)
      },
      update: function (id, params) {
        return adapter.patch(`api/v4/ku/equipment_data/${id}.json`, params);
      },
      export: function (params = {}) {
        return adapter.get("api/v4/ku/equipment_data.xlsx", {
          params: params,
          responseType: "blob",
        });
      },
    },
    equipments: {
      index: function (params = {}) {
        return adapter.get("api/v4/ku/equipments.json", { params: params });
      },
      get: function (id) {
        return adapter.get(`api/v4/ku/equipments/${id}.json`);
      },
      create: function (params = {}) {
        return adapter.post("api/v4/ku/equipments.json", params);
      },
      update: function (id, params = {}) {
        return adapter.patch(`api/v4/ku/equipments/${id}.json`, params);
      }
    },
    equipment_types: {
      index: function (params = {}) {
        return adapter.get("api/v4/ku/equipment_types.json", { params: params });
      },
      show: function (id) {
        return adapter.get(`api/v4/ku/equipment_types/${id}.json`);
      },
      create: function (params = {}) {
        return adapter.post("api/v4/ku/equipment_types.json", params);
      },
      update: function (id, params = {}) {
        return adapter.patch(`api/v4/ku/equipment_types/${id}.json`, params);
      }
    },
    additional_equipments: {
      index: function (params = {}) {
        return adapter.get("api/v4/ku/additional_equipments.json", { params: params });
      },
      show: function (id) {
        return adapter.get(`api/v4/ku/additional_equipments/${id}.json`);
      },
      create: function (params = {}) {
        return adapter.post("api/v4/ku/additional_equipments.json", params);
      },
      update: function (id, params = {}) {
        return adapter.patch(`api/v4/ku/additional_equipments/${id}.json`, params);
      }
    }
  }
}