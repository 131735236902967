import deepmerge from "@node/deepmerge"

let locales = {};
const modules = import.meta.glob("@o-din-parking/../../config/locales/**/*.yml", { eager: true });

for (const path in modules) {
  locales = deepmerge(locales, modules[path].default);
}

import store from "@o-din-parking/src/parking/store.js";
import menu from "@o-din-parking/src/parking/menu.json";

// Issues
import ParkingIssuesIndex from "@o-din-parking/src/views/parking/issues/Index.vue"
import ParkingIssuesArchive from "@o-din-parking/src/views/parking/issues/Archive.vue"
import ParkingIssuesNew from "@o-din-parking/src/views/parking/issues/New.vue"
import ParkingIssuesShow from "@o-din-parking/src/views/parking/issues/Show.vue"
// Categories
import ParkingCategoriesIndex from "@o-din-parking/src/views/parking/categories/Index.vue"
import ParkingCategoryForm from "@o-din-parking/src/views/parking/categories/Form.vue"
import ParkingCategoryShow from "@o-din-parking/src/views/parking/categories/Show.vue"
// Vehicles
import ParkingVehiclesIndex from "@o-din-parking/src/views/parking/vehicles/Index.vue"
import ParkingVehiclesShow from "@o-din-parking/src/views/parking/vehicles/Show.vue"
import ParkingVehiclesForm from "@o-din-parking/src/views/parking/vehicles/Form.vue"
// Vehicle Types
import ParkingVehicleTypesIndex from "@o-din-parking/src/views/parking/vehicle_types/Index.vue"
import ParkingVehicleTypesForm from "@o-din-parking/src/views/parking/vehicle_types/Form.vue"
// Parking Slots
import ParkingSlotsIndex from "@o-din-parking/src/views/parking/parking_slots/Index.vue"
import ParkingSlotsForm from "@o-din-parking/src/views/parking/parking_slots/Form.vue"
import ParkingSlotsShow from "@o-din-parking/src/views/parking/parking_slots/Show.vue"
// Parking Passes
import ParkingPassesIndex from "@o-din-parking/src/views/parking/passes/Index.vue"
import ParkingPassesForm from "@o-din-parking/src/views/parking/passes/Form.vue"
import ParkingPassesShow from "@o-din-parking/src/views/parking/passes/Show.vue"
// Simple Session
import ParkingSimpleSessionsIndex from "@o-din-parking/src/views/parking/simple_sessions/Index.vue"

export default {
  routes: [
    { path: '/parking/issues/', component: ParkingIssuesIndex },
    { path: '/parking/issues/archive', component: ParkingIssuesArchive },
    { path: '/parking/issues/new', component: ParkingIssuesNew },
    { path: '/parking/issues/:id', component: ParkingIssuesShow },
    { path: '/parking/categories/', component: ParkingCategoriesIndex },
    { path: '/parking/categories/new', component: ParkingCategoryForm },
    { path: '/parking/categories/:id/edit', component: ParkingCategoryForm },
    { path: '/parking/categories/:id', component: ParkingCategoryShow },
    { path: '/parking/vehicles/', component: ParkingVehiclesIndex },
    { path: '/parking/vehicles/new', component: ParkingVehiclesForm },
    { path: '/parking/vehicles/:id/edit', component: ParkingVehiclesForm },
    { path: '/parking/vehicles/:id', component: ParkingVehiclesShow },
    { path: '/parking/vehicle_types/', component: ParkingVehicleTypesIndex },
    { path: '/parking/vehicle_types/new', component: ParkingVehicleTypesForm },
    { path: '/parking/vehicle_types/:id/edit', component: ParkingVehicleTypesForm },
    { path: '/parking/parking_slots/', component: ParkingSlotsIndex },
    { path: '/parking/parking_slots/new', component: ParkingSlotsForm },
    { path: '/parking/parking_slots/:id/edit', component: ParkingSlotsForm },
    { path: '/parking/parking_slots/:id', component: ParkingSlotsShow },
    { path: '/parking/passes/', component: ParkingPassesIndex },
    { path: '/parking/passes/new', component: ParkingPassesForm },
    { path: '/parking/passes/:id/edit', component: ParkingPassesForm },
    { path: '/parking/passes/:id', component: ParkingPassesShow },
    { path: '/parking/simple_sessions/', component: ParkingSimpleSessionsIndex }
  ],
  locales,
  store,
  menu: [menu]
}
