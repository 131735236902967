import deepmerge from "@node/deepmerge";

let locales = {};
const modules = import.meta.glob("@o-din-lk/../../config/locales/**/*.yml", { eager: true });

for (const path in modules) {
  locales = deepmerge(locales, modules[path].default);
}

import store from "@o-din-lk/src/store_lk"

// Post
import PostsIndex from "@o-din-lk/src/views/lk/posts/Index.vue"
import PostForm from "@o-din-lk/src/views/lk/posts/Form.vue"
import PostShow from "@o-din-lk/src/views/lk/posts/Show.vue"
// Document
import DocumentsIndex from "@o-din-lk/src/views/lk/documents/Index.vue"
import DocumentForm from "@o-din-lk/src/views/lk/documents/Form.vue"
// Surveys
import SurveysIndex from "@o-din-lk/src/views/lk/surveys/Index.vue"
import SurveysForm from "@o-din-lk/src/views/lk/surveys/Form.vue"
import SurveysShow from "@o-din-lk/src/views/lk/surveys/Show.vue"
// Surveys
import SurveyResultsForm from "@o-din-lk/src/views/lk/survey_results/Form.vue"

export default {
  routes: [
    { path: '/lk/posts', component: PostsIndex },
    { path: '/lk/posts/new', component: PostForm },
    { path: '/lk/posts/:id/edit', component: PostForm },
    { path: '/lk/posts/:id', component: PostShow },
    { path: '/lk/documents', component: DocumentsIndex },
    { path: '/lk/documents/new', component: DocumentForm },
    { path: '/lk/documents/:id/edit', component: DocumentForm },
    { path: '/lk/surveys', component: SurveysIndex },
    { path: '/lk/surveys/new', component: SurveysForm },
    { path: '/lk/surveys/:id/edit', component: SurveysForm },
    { path: '/lk/surveys/:id', component: SurveysShow },
    { path: '/lk/survey_results/:id/edit', component: SurveyResultsForm }
  ],
  locales,
  store
}
