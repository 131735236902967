import { createApp } from '@o-din/app';

import Maintenance from '@o-din-maintenance/src/o-din-maintenance';
import Ppr from '@o-din-ppr/src/o-din-ppr';
import Lk from '@o-din-lk/src/o-din-lk';
import Pm from '@o-din-pm/src/o-din-pm';
import Ku from '@o-din-ku/src/o-din-ku';
import Audit from '@o-din-audit/src/o-din-audit';
import Rounds from '@o-din-rounds/src/o-din-rounds';
import Parking from '@o-din-parking/src/o-din-parking';
import Report from '@o-din-report/src/o-din-report';
import Stock from '@o-din-stock/src/o-din-stock';

createApp('#app', [Audit, Ku, Lk, Pm, Maintenance, Rounds, Report, Parking, Ppr, Stock]);
