import adapter from "@o-din/src/adapter";

export default {
  contract_comments: {
    index: function(params = {}) {
      return adapter.get('pm/contract_comments.xlsx', { params: params }, { headers: { "Response-Type": "blob" }})
    }
  },
  pm: {
    payment_types: {
      index: function(params = {}) {
        return adapter.get('/api/v4/pm/payment_types.json', { params: params })
      },
      create: function(params) {
        return adapter.post('/api/v4/pm/payment_types.json', params)
      },
      update: function(id, params) {
        return adapter.patch(`/api/v4/pm/payment_types/${id}.json`, params)
      },
      destroy: function(id) {
        return adapter.delete(`api/v4/pm/payment_types/${id}.json`)
      },
      show: function(id) {
        return adapter.get(`/api/v4/pm/payment_types/${id}.json`)
      }
    },
    offers: {
      index: function(params = {}) {
        return adapter.get('/api/v4/pm/offers.json', { params: params })
      },
      create: function(params) {
        return adapter.post('/api/v4/pm/offers.json', params)
      },
      show: function(id) {
        return adapter.get(`/api/v4/pm/offers/${id}.json`)
      },
      update: function(id, params) {
        return adapter.patch(`/api/v4/pm/offers/${id}.json`, params)
      },
      destroy: function(id) {
        return adapter.delete(`api/v4/pm/offers/${id}.json`)
      },
    },
    payment_purposes: {
      create: function(params = {}) {
        return adapter.post('/api/v4/pm/payment_purposes.json', { pm_payment_purpose: params })
      },
      show: function(id) {
        return adapter.get(`/api/v4/pm/payment_purposes/${id}.json`)
      },
      index: function(params = {}) {
        return adapter.get('/api/v4/pm/payment_purposes.json', { params: params })
      },
      update: function(id, params) {
        return adapter.patch(`/api/v4/pm/payment_purposes/${id}.json`, { pm_payment_purpose: params })
      }
    },
    payments: {
      create: function(params = {}) {
        return adapter.post('/api/v4/pm/payments.json', params, { headers: { "Content-Type": "multipart/form-data" }})
      },
      index: function(params = {}) {
        return adapter.get('/api/v4/pm/payments.json', { params: params })
      },
      show: function(id) {
        return adapter.get(`/api/v4/pm/payments/${id}.json`)
      },
      update: function(id, form) {
        return adapter.patch(`/api/v4/pm/payments/${id}.json`, form)
      }
    },
    currencies: {
      index: function(params = {}) {
        return adapter.get('/api/v4/pm/currencies', { params: params }, { headers: { "Content-Type": "multipart/form-data" }})
      },
      create: function(params) {
        return adapter.post('/api/v4/pm/currencies.json', params)
      },
      destroy: function(id) {
        return adapter.delete(`api/v4/pm/currencies/${id}.json`)
      },
      update: function(id, params) {
        return adapter.patch(`api/v4/pm/currencies/${id}.json`, params)
      },
      show: function(id) {
        return adapter.get(`api/v4/pm/currencies/${id}.json`)
      }
    },
    contracts: {
      index: function(params = {}) {
        return adapter.get('/api/v4/pm/contracts', { params: params })
      },
      export: function(params = {}) {
        return adapter.get('api/v4/pm/contracts.xlsx', { params: params, responseType: 'blob' })
      },
      create: function(params = {}) {
        return adapter.post('api/v4/pm/contracts.json', params, { headers: { "Content-Type": "multipart/form-data" }})
      },
      get: function(id) {
        return adapter.get(`api/v4/pm/contracts/${id}.json`)
      },
      update: function(id, form) {
        return adapter.patch(`api/v4/pm/contracts/${id}.json`, form)
      },
      generate_expenses: function(id, params = {}) {
        return adapter.post(`api/v4/pm/contracts/${id}/generate_expenses.json`, params, { headers: { "Content-Type": "multipart/form-data" }})
      },
    },
    expenses: {
      index: function(params = {}) {
        return adapter.get('/api/v4/pm/expenses', { params: params })
      },
      create: function(params = {}) {
        return adapter.post('api/v4/pm/expenses.json', params, { headers: { "Content-Type": "multipart/form-data" }})
      },
      get: function(id) {
        return adapter.get(`api/v4/pm/expenses/${id}.json`)
      },
      update: function(id, form) {
        return adapter.patch(`api/v4/pm/expenses/${id}.json`, form)
      },
      manual_send: function(id, form) {
        return adapter.post(`api/v4/pm/expenses/${id}/manual_send.json`, form)
      },
      destroy: function(id) {
        return adapter.delete(`api/v4/pm/expenses/${id}.json`)
      }
    },
    documents: {
      destroy: function(id) {
        return adapter.delete(`pm/documents/${id}.json`)
      }
    },
    dashboards: {
      index: function(params = {}) {
        return adapter.get('/api/v4/pm/dashboards.json', { params: params })
      },
      create: function(params = {}) {
        return adapter.post('/api/v4/pm/dashboards.json', { params: params })
      },
    }
  }
}
