import deepmerge from "@node/deepmerge"

let locales = {}
const modules = import.meta.glob("@o-din-pm/../../config/locales/**/*.yml", { eager: true });

for (const path in modules) {
  locales = deepmerge(locales, modules[path].default);
}

import store from "@o-din-pm/src/pm/store";
import menu from "@o-din-pm/src/pm/menu.json";

// Contracts
import PmContractForm from "@o-din-pm/src/views/pm/contracts/Form.vue"
import PmContractIndex from "@o-din-pm/src/views/pm/contracts/Index.vue"
import PmContractShow from "@o-din-pm/src/views/pm/contracts/Show.vue"
// Expenses
import PmExpenseForm from "@o-din-pm/src/views/pm/expenses/Form.vue"
import PmExpenseIndex from "@o-din-pm/src/views/pm/expenses/Index.vue"
import PmExpenseShow from "@o-din-pm/src/views/pm/expenses/Show.vue"
// Payments
import PmPaymentForm from "@o-din-pm/src/views/pm/payments/Form.vue"
import PmPaymentIndex from "@o-din-pm/src/views/pm/payments/Index.vue"
// PaymentPurpose
import PmPaymentPurposeIndex from "@o-din-pm/src/views/pm/payment_purposes/Index.vue"
import PmPaymentPurposeUpdate from "@o-din-pm/src/views/pm/payment_purposes/Update.vue"
import PmPaymentPurposeShow from "@o-din-pm/src/views/pm/payment_purposes/Show.vue"
// PaymentsType
import PmPaymentTypeIndex from "@o-din-pm/src/views/pm/payment_types/Index.vue"
import PmPaymentTypeForm from "@o-din-pm/src/views/pm/payment_types/Form.vue"
// Currencies
import PmCurrenciesIndex from "@o-din-pm/src/views/pm/currencies/Index.vue"
import PmCurrenciesForm from "@o-din-pm/src/views/pm/currencies/Form.vue"
// Dashboard
import DashboardIndex from "@o-din-pm/src/views/pm/dashboard/Index.vue"
// Offers
import PmOffersIndex from "@o-din-pm/src/views/pm/offers/Index.vue"
import PmOffersForm from "@o-din-pm/src/views/pm/offers/Form.vue"

export default {
  routes: [
    { path: "/pm/dashboards", component: DashboardIndex },
    { path: "/pm/offers", component: PmOffersIndex },
    { path: "/pm/offers/new", component: PmOffersForm },
    { path: "/pm/offers/:id/edit", component: PmOffersForm },
    { path: "/pm/contracts/new", component: PmContractForm },
    { path: "/pm/contracts/:id/edit", component: PmContractForm },
    { path: "/pm/contracts", component: PmContractIndex },
    { path: "/pm/contracts/:id", component: PmContractShow },
    { path: "/pm/expenses/new", component: PmExpenseForm },
    { path: "/pm/expenses/:id/edit", component: PmExpenseForm },
    { path: "/pm/expenses", component: PmExpenseIndex },
    { path: "/pm/expenses/:id", component: PmExpenseShow },
    { path: "/pm/payments/new", component: PmPaymentForm },
    { path: "/pm/payments/:id/edit", component: PmPaymentForm },
    { path: "/pm/payments", component: PmPaymentIndex },
    { path: "/pm/payment_purposes", component: PmPaymentPurposeIndex },
    { path: "/pm/payment_purposes/:id", component: PmPaymentPurposeShow },
    { path: "/pm/payment_purposes/:id/edit", component: PmPaymentPurposeUpdate },
    { path: "/pm/payment_types", component: PmPaymentTypeIndex },
    { path: "/pm/payment_types/new", component: PmPaymentTypeForm },
    { path: "/pm/payment_types/:id/edit", component: PmPaymentTypeForm },
    { path: "/pm/currencies", component: PmCurrenciesIndex },
    { path: "/pm/currencies/:id/edit", component: PmCurrenciesForm },
    { path: "/pm/currencies/new", component: PmCurrenciesForm },

  ],
  locales,
  store,
  menu: [menu]
}
