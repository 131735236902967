import adapter from "@o-din/src/adapter"

export default {
  issue_stock_materials: {
    destroy: function(params = {}) {
      return adapter.delete('api/v4/issue_stock_materials.json', {params: params})
    }
  },
  stock: {
    materials: {
      index: function (params = {}) {
        return adapter.get('api/v4/stock/materials.json', {params: params})
      },
      get: function (id) {
        return adapter.get(`api/v4/stock/materials/${id}.json`)
      },
      create: function (params) {
        return adapter.post('api/v4/stock/materials.json', params)
      },
      update: function (id, params) {
        return adapter.patch(`api/v4/stock/materials/${id}.json`, params)
      },
      destroy: function (id) {
        return adapter.delete(`api/v4/stock/materials/${id}.json`)
      }
    },
    items: {
      index: function (params = {}) {
        return adapter.get('api/v4/stock/items.json', {params: params})
      },
      get: function (id) {
        return adapter.get(`api/v4/stock/items/${id}.json`)
      },
      create: function (params) {
        return adapter.post('api/v4/stock/items.json', params)
      },
      update: function (id, params) {
        return adapter.patch(`api/v4/stock/items/${id}.json`, params)
      },
      destroy: function (id) {
        return adapter.delete(`api/v4/stock/items/${id}.json`)
      }
    },
    categories: {
      index: function (params = {}) {
        return adapter.get('api/v4/stock/categories.json', {params: params})
      },
      get: function (id) {
        return adapter.get(`api/v4/stock/categories/${id}.json`)
      },
      create: function(params = {}) {
        return adapter.post("api/v4/stock/categories.json", {stock_category: params})
      },
      update: function(id, params = {}) {
        return adapter.patch(`api/v4/stock/categories/${id}.json`, {stock_category: params})
      }
    },
    requests: {
      create: function(params = {}) {
        return adapter.post("api/v4/stock/requests.json", { stock_request: params })
      },
    }
  }
}
